import {
  NSocialPost,
  QuillDelta,
  ShareAttachmentPreview,
  SocialContact,
  SocialGroup,
  SocialPost,
  SocialPostAttachment,
  SocialTemporalPost,
  TRANSLATION_STATUS,
} from '@ao/data-models';
import { SocialReactionAggregatePerType } from '@ao/shared-data-models';
import { flatMapDeep, mapObject, mapToObject, omit, uuid } from '@ao/utilities';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import * as SocialActions from './social-store.actions';

export const SOCIAL_FEATURE_KEY = 'social';
export const postsAdapter: EntityAdapter<NSocialPost> = createEntityAdapter();
export const groupsAdapter: EntityAdapter<SocialGroup> = createEntityAdapter();
export const reactionsAdapter: EntityAdapter<SocialContact> = createEntityAdapter();
export const postTranslationsAdapter: EntityAdapter<{
  id: number;
  status: TRANSLATION_STATUS;
  content: QuillDelta;
}> = createEntityAdapter();

export interface State {
  contacts: { [contactId: number]: SocialContact };
  groups: EntityState<SocialGroup>;
  newPostsPerGroup: { [groupId: number]: number };
  posts: EntityState<NSocialPost>;
  postTranslations: EntityState<{ id: number; status: TRANSLATION_STATUS; content: QuillDelta }>;
  activeTranslations: number[];
  comments: { [postId: number]: SocialPost['id'][] };
  lastComments: { [postId: number]: SocialPost['id'][] };

  reactions: EntityState<SocialContact>;
  views: { [contentId: number]: SocialContact['id'][] };

  attachments: { [id: string]: SocialPostAttachment };
  attachmentsByRef: { [ref: string]: SocialPostAttachment['id'][] };

  shareAttachmentPreviews: {
    [id: number]: ShareAttachmentPreview;
  };

  limbo: {
    [id: string]: SocialTemporalPost;
  };

  pollingMedia: {
    [id: number]: boolean;
  };

  feed: {
    loading: boolean;
    hasAccess: boolean;
    hasMore: boolean;
    error: any;
    initialLoaded: boolean;
  };
  messagePostId: number;
}

export interface SocialPartialState {
  readonly [SOCIAL_FEATURE_KEY]: State;
}

export const initialState: State = {
  contacts: {},
  groups: groupsAdapter.getInitialState(),
  newPostsPerGroup: {},
  posts: postsAdapter.getInitialState(),
  postTranslations: postTranslationsAdapter.getInitialState(),
  activeTranslations: [],
  comments: {},
  lastComments: {},
  reactions: reactionsAdapter.getInitialState(),
  views: {},
  attachments: {},
  attachmentsByRef: {},
  shareAttachmentPreviews: {},
  limbo: {},
  pollingMedia: {},
  feed: {
    loading: false,
    hasAccess: true,
    hasMore: false,
    error: null,
    initialLoaded: false,
  },
  messagePostId: null,
};

function getParentSocialPostIds(posts: EntityState<NSocialPost>, post: NSocialPost) {
  const parentIds = [];
  // ignore for message comments (the parent post is the message)
  if (post && post.groupType !== 'message') {
    let p = post;
    while (p.parentId) {
      const parent = posts.entities[p.parentId];
      parentIds.push(parent.id);
      p = parent;
    }
  }

  return parentIds;
}

const socialReducer = createReducer(
  initialState,
  on(SocialActions.SetStateForTesting, (state, { newState }): State => {
    return newState;
  }),
  on(SocialActions.LoadContactSuccess, (state, { contact }): State => {
    return {
      ...state,
      contacts: {
        ...state.contacts,
        [contact?.id]: contact,
      },
    };
  }),
  on(SocialActions.CreatePostAttachment, (state, { ref, attachmentId, file }): State => {
    const attachments = state.attachmentsByRef[ref] || [];
    const attachment = {
      id: attachmentId,
      uploaded: false,
      processed: false,
      progress: 0,
      media: null,
      name: file.name,
      type: file.type,
      size: file.size,
    };
    return {
      ...state,
      attachments: {
        ...state.attachments,
        [attachmentId]: attachment,
      },
      attachmentsByRef: {
        ...state.attachmentsByRef,
        [ref]: [...attachments, attachmentId],
      },
    };
  }),
  on(SocialActions.CreatePostAttachmentProgress, (state, { ref, attachmentId, progress }): State => {
    return {
      ...state,
      attachments: {
        ...state.attachments,
        [attachmentId]: {
          ...state.attachments[attachmentId],
          // Don't make 100% until the request actually finishes
          progress: Math.min(progress, 90),
        },
      },
    };
  }),
  on(SocialActions.CreatePostAttachmentSuccess, (state, { attachmentId, media, hasDelayedProcessing }): State => {
    return {
      ...state,
      attachments: {
        ...state.attachments,
        [attachmentId]: {
          ...state.attachments[attachmentId],
          uploaded: true,
          media,
          ...(hasDelayedProcessing
            ? {
                progress: 0,
                processed: false,
              }
            : {
                progress: 100,
                processed: true,
              }),
        },
      },
    };
  }),
  on(SocialActions.PollPostAttachmentUpdate, (state, { attachmentId, progress, media }): State => {
    const attachment = state.attachments[attachmentId];
    const mediaId = attachment.media && attachment.media.id;

    return {
      ...state,
      attachments: {
        ...state.attachments,
        [attachmentId]: {
          ...attachment,
          type: media.type || attachment.type,
          progress,
        },
      },
      // Update media in posts
      posts: postsAdapter.map(
        (post) => {
          const containsMedia = post.media.some((m) => m.id === mediaId);
          return !containsMedia
            ? post
            : {
                ...post,
                media: post.media.map((m) => (m.id === mediaId ? { ...m, progress } : m)),
              };
        },
        { ...state.posts },
      ),
    };
  }),
  on(SocialActions.PollPostAttachmentSuccess, (state, { attachmentId, media }): State => {
    const attachment = state.attachments[attachmentId];
    return {
      ...state,
      // If there's a postId, means the post was created already
      ...(attachment.postId
        ? {
            posts: postsAdapter.updateOne(
              {
                id: attachment.postId,
                changes: {
                  // Update media with updated data
                  media: state.posts.entities[attachment.postId].media.map((m) =>
                    m.id === media.id ? { ...m, ...media } : m,
                  ),
                },
              },
              { ...state.posts },
            ),
            attachments: omit(state.attachments, [attachmentId]),
          }
        : {
            attachments: {
              ...state.attachments,
              [attachmentId]: {
                ...attachment,
                progress: 100,
                processed: true,
                type: media.type || attachment.type,
                media: {
                  ...state.attachments[attachmentId].media,
                  ...media,
                },
              },
            },
          }),
    };
  }),
  on(SocialActions.PollMediaItem, (state, { mediaId }): State => {
    return {
      ...state,
      pollingMedia: {
        ...state.pollingMedia,
        [mediaId]: true,
      },
    };
  }),
  on(SocialActions.PollMediaItemUpdate, (state, { postId, progress, mediaId }): State => {
    return {
      ...state,
      posts: postsAdapter.updateOne(
        {
          id: postId,
          changes: {
            // Update media with updated data
            media: state.posts.entities[postId].media.map((m) => (m.id === mediaId ? { ...m, progress } : m)),
          },
        },
        { ...state.posts },
      ),
      pollingMedia: omit(state.pollingMedia, [mediaId]),
    };
  }),
  on(SocialActions.PollMediaItemSuccess, SocialActions.PollMediaItemFail, (state, { postId, media }): State => {
    return {
      ...state,
      posts: postsAdapter.updateOne(
        {
          id: postId,
          changes: {
            // Update media with updated data
            media: state.posts.entities[postId].media.map((m) => (m.id === media.id ? { ...m, ...media } : m)),
          },
        },
        { ...state.posts },
      ),
      pollingMedia: omit(state.pollingMedia, [media.id]),
    };
  }),
  on(SocialActions.RemovePostAttachment, (state, { ref, attachmentId }): State => {
    const { [attachmentId]: _, ...attachments } = state.attachments;
    return {
      ...state,
      attachments,
      attachmentsByRef: {
        ...state.attachmentsByRef,
        [ref]: state.attachmentsByRef[ref].filter((id) => id !== attachmentId),
      },
    };
  }),
  on(SocialActions.LoadOnePost, (state, action): State => {
    return {
      ...state,
      messagePostId: action.isMessageWithSocialFooter ? state.messagePostId : null,
    };
  }),
  on(SocialActions.LoadOnePostSuccess, (state, { post, isMessageWithSocialFooter }): State => {
    // Quick fix implementing old lodash functionality for flatMapDeep (https://actimo.atlassian.net/browse/BUGS-2783)
    // TODO fix and use flattenSocialComments again
    // const flattened: SocialPost[] = [post, ...flattenSocialComments(post.comments || [])];
    const flattened: SocialPost[] = [post, ...flatMapDeep(post.comments, (c) => [c, ...c.comments])];
    const newPosts: NSocialPost[] = flattened.map(({ comments, author, ...p }) => {
      return { ...p, author: author?.id };
    });

    const flattenedForContacts: SocialPost[] =
      // post.groupType === 'message' ? [...flattenSocialComments(post.comments || [])] : flattened;
      post.groupType === 'message' ? [...flatMapDeep(post.comments, (c) => [c, ...c.comments])] : flattened;

    return {
      ...state,
      contacts: {
        ...state.contacts,
        ...mapToObject(
          flattenedForContacts,
          (c) => c.author.id,
          (p) => p.author,
        ),
      },
      comments: {
        ...state.comments,
        ...mapToObject(
          flattened,
          (p) => p.id,
          (p) => (p.comments || []).map((c) => c.id),
        ),
      },
      lastComments: {
        ...state.lastComments,
        ...mapToObject(
          flattened,
          (p) => p.id,
          (p) => (p.comments || []).map((c) => c.id).slice(-1),
        ),
      },
      posts: postsAdapter.upsertMany(newPosts, { ...state.posts }),
      messagePostId: isMessageWithSocialFooter ? post.id : state.messagePostId,
    };
  }),
  on(SocialActions.LoadPosts, (state, { append }): State => {
    return {
      ...state,
      posts: append ? state.posts : postsAdapter.removeAll({ ...state.posts }),
      feed: {
        ...state.feed,
        loading: true,
        error: null,
      },
    };
  }),
  on(SocialActions.LoadPostsSuccess, (state, { posts, hasMore }): State => {
    // Quick fix implementing old lodash functionality for flatMapDeep (https://actimo.atlassian.net/browse/BUGS-2783)
    // TODO fix and use flattenSocialLastComments and flattenSocialComments again
    /*
    const flattened: SocialPost[] = flattenSocialLastComments(posts);
    const flattenedForAuthors: SocialPost[] = flattenSocialComments(posts);
    */
    const flattened: SocialPost[] = flatMapDeep(posts, (p) => [
      p,
      ...flatMapDeep(p.lastComments, (c) => [c, ...c.lastComments]),
    ]);

    const newPosts: NSocialPost[] = flattened.map(({ comments, lastComments, author, ...p }) => {
      return { ...p, author: author?.id };
    });

    return {
      ...state,
      contacts: {
        ...state.contacts,
        ...mapToObject(
          // flattenedForAuthors,
          flattened,
          (p) => p.author.id,
          (p) => p.author,
        ),
      },
      posts: postsAdapter.upsertMany(newPosts, { ...state.posts }),
      postTranslations: postTranslationsAdapter.updateMany(
        (state.postTranslations.ids as number[]).map((id) => ({
          id,
          changes: { status: TRANSLATION_STATUS.original },
        })),
        { ...state.postTranslations },
      ),
      lastComments: {
        ...state.lastComments,
        ...mapToObject(
          flattened,
          (p) => p.id,
          (p) => p.lastComments.map((c) => c.id),
        ),
      },
      feed: {
        ...state.feed,
        loading: false,
        hasAccess: true,
        hasMore,
        error: null,
        initialLoaded: true,
      },
    };
  }),
  on(SocialActions.LoadPostsFail, (state, { error }): State => {
    return {
      ...state,
      feed: {
        ...state.feed,
        loading: false,
        hasAccess: !(error && error.error && error.error.code === 'NO_ACCESS'),
        error, // TODO: handle errors
      },
    };
  }),
  on(
    SocialActions.CreatePost,
    (
      state,
      { tempId, parentId, content, groupId, groupType, linkPreview, shareAttachments, dynamicGroupId },
    ): State => {
      const ref = parentId ? `REPLY-${parentId}` : 'POST';
      return {
        ...state,
        limbo: {
          ...state.limbo,
          [tempId]: {
            tempId,
            parentId,
            content,
            groupId,
            groupType,
            attachments: state.attachmentsByRef[ref] || [],
            action: 'CREATING',
            linkPreview,
            shareAttachments,
            dynamicGroupId,
          },
        },
        attachmentsByRef: omit(state.attachmentsByRef, ref),
      };
    },
  ),
  on(SocialActions.CancelCreatePost, (state, { tempId }): State => {
    const tempPost = state.limbo[tempId];
    return {
      ...state,
      limbo: omit(state.limbo, tempId),
      attachments: omit(state.attachments, tempPost.attachments),
    };
  }),
  on(SocialActions.CreatePostSuccess, (state, { post, tempId, showToast }): State => {
    const { author, ...socialPost } = post;
    const tempPost = state.limbo[tempId];
    const newPost: NSocialPost = { ...socialPost, author: author?.id };
    const parentIds = getParentSocialPostIds(state.posts, newPost);
    const postsWithNew = postsAdapter.addOne(newPost, { ...state.posts });
    return {
      ...state,
      posts: postsAdapter.map(
        (p) => {
          if (parentIds.includes(p.id)) {
            return {
              ...p,
              commentCount: newPost.commentCount + 1,
            };
          }
          return p;
        },
        { ...postsWithNew },
      ),
      contacts: {
        ...state.contacts,
        [author.id]: author,
      },
      // if it has a parentId then it's a comment
      comments: socialPost.parentId
        ? {
            ...state.comments,
            [socialPost.parentId]: [...(state.comments[socialPost.parentId] || []), socialPost.id],
          }
        : state.comments,
      lastComments: socialPost.parentId
        ? {
            ...state.lastComments,
            [socialPost.parentId]: [socialPost.id],
          }
        : state.lastComments,
      limbo: omit(state.limbo, tempId),
      // Remove already processed attachments
      attachments: omit(
        {
          ...state.attachments,
          // Update attachments with newly created postId
          ...mapToObject(
            tempPost.attachments,
            (id) => id,
            (id) => ({ postId: socialPost.id, ...state.attachments[id] }),
          ),
        },
        tempPost.attachments.filter((id) => state.attachments[id].processed),
      ),
    };
  }),
  on(SocialActions.CreatePostFail, (state, { tempId }): State => {
    return {
      ...state,
      limbo: omit(state.limbo, tempId),
    };
  }),
  on(SocialActions.EditPost, (state, { tempId, postId, content, linkPreview, shareAttachments }): State => {
    const ref = `POST-${postId}`;
    return {
      ...state,
      limbo: {
        ...state.limbo,
        [tempId]: {
          tempId,
          content,
          postId,
          attachments: state.attachmentsByRef[ref] || [],
          action: 'EDITING',
          linkPreview,
          shareAttachments,
        },
      },
      attachmentsByRef: omit(state.attachmentsByRef, ref),
    };
  }),
  on(SocialActions.CancelEditPost, (state, { tempId }): State => {
    const tempPost = state.limbo[tempId];
    return {
      ...state,
      limbo: omit(state.limbo, tempId),
      attachments: omit(state.attachments, tempPost.attachments),
    };
  }),
  on(SocialActions.EditPostSuccess, (state, { tempId, post }): State => {
    const { comments, lastComments, author, pinned, ...socialPost } = post;
    const shareMessageId = post.attachments?.find((attachment) => attachment.type === 'message')?.id;
    const tempPost = state.limbo[tempId];
    return {
      ...state,
      posts: postsAdapter.map(
        (p) => {
          if (p.id === post.id) {
            return {
              ...p,
              ...socialPost,
              pinned: !!pinned,
              author: author?.id,
              messageAttachmentId: shareMessageId,
            };
          } else if (p.pinned) {
            return {
              ...p,
              pinned: false,
            };
          }
          return p;
        },
        { ...state.posts },
      ),
      contacts: {
        ...state.contacts,
        [author.id]: author,
      },
      limbo: omit(state.limbo, tempId),
      attachments: omit(
        {
          ...state.attachments,
          ...mapToObject(
            tempPost.attachments,
            (id) => id,
            (id) => ({ postId: socialPost.id, ...state.attachments[id] }),
          ),
        },
        tempPost.attachments.filter((id) => state.attachments[id].processed),
      ),
    };
  }),
  on(SocialActions.LoadPostReactionsSuccess, (state, { contacts }): State => {
    return {
      ...state,
      reactions: reactionsAdapter.setAll(contacts, state.reactions),
      contacts: {
        ...state.contacts,
        ...mapToObject(
          contacts,
          (c) => c.id,
          (c) => c,
        ),
      },
    };
  }),
  on(SocialActions.ReactToPostSuccess, (state, { postId, reactionType }): State => {
    const { reactionsCount: reactionsCount, reactions, reacted } = state.posts.entities[postId];

    let updatedReactions: SocialReactionAggregatePerType[] = reactions
      .map((reaction) => (reacted === reaction.reaction_type ? { ...reaction, count: reaction.count - 1 } : reaction))
      .filter((reaction) => reaction.count)
      .map((reaction) =>
        reaction.reaction_type === reactionType ? { ...reaction, count: reaction.count + 1 } : reaction,
      );

    if (!updatedReactions.some((reaction) => reaction.reaction_type === reactionType)) {
      updatedReactions = [...updatedReactions, { count: 1, reaction_type: reactionType, post_id: postId }];
    }

    return {
      ...state,
      posts: postsAdapter.updateOne(
        {
          id: postId,
          changes: {
            reacted: reactionType,
            reactionsCount: reacted ? reactionsCount : reactionsCount + 1, // only add if no previous reaction
            reactions: updatedReactions,
          },
        },
        { ...state.posts },
      ),
    };
  }),
  on(SocialActions.UnreactPostSuccess, (state, { postId }): State => {
    const { reactionsCount: reactionsCount, reactions, reacted } = state.posts.entities[postId];

    const updatedReactions: SocialReactionAggregatePerType[] = reactions
      .map((reaction) => (reaction.reaction_type === reacted ? { ...reaction, count: reaction.count - 1 } : reaction))
      .filter((reaction) => reaction.count > 0);

    return {
      ...state,
      posts: postsAdapter.updateOne(
        { id: postId, changes: { reacted: null, reactionsCount: reactionsCount - 1, reactions: updatedReactions } },
        { ...state.posts },
      ),
    };
  }),
  on(SocialActions.StartEditingPost, (state, { postId }): State => {
    const id = `POST-${postId}`;
    const content = state.posts.entities[postId];
    const attachments = ((content && content.media) || []).map((media) => ({
      id: uuid(),
      name: media.name,
      type: media.type,
      size: media.size,
      progress: 100,
      uploaded: true,
      processed: true,
      media,
    }));
    return {
      ...state,
      attachments: {
        ...state.attachments,
        ...mapToObject(
          attachments,
          (c) => c.id,
          (c) => c,
        ),
      },
      attachmentsByRef: {
        ...state.attachmentsByRef,
        [id]: attachments.map((a) => a.id),
      },
    };
  }),
  on(SocialActions.DeletePostSuccess, (state, { postId }): State => {
    // sub-comments will be removed as well
    const removedPost = state.posts.entities[postId];
    const removedCommentCount = removedPost.commentCount + 1;
    const posts = postsAdapter.removeOne(postId, { ...state.posts });
    const removedPostParentIds = getParentSocialPostIds(state.posts, <NSocialPost>removedPost);

    return {
      ...state,
      posts: postsAdapter.map(
        (p) => {
          if (removedPostParentIds.includes(p.id)) {
            return {
              ...p,
              commentCount: p.commentCount - removedCommentCount,
            };
          }
          return p;
        },
        { ...posts },
      ),
      lastComments: mapObject(omit(state.lastComments, postId), (comments) => comments.filter((p) => p !== postId)),
      comments: mapObject(omit(state.comments, postId), (comments) => comments.filter((p) => p !== postId)),

      views: omit(state.views, postId),
    };
  }),
  on(SocialActions.LoadPostViewsSuccess, (state, { postId, contacts }): State => {
    return {
      ...state,
      views: {
        ...state.views,
        [postId]: contacts.map((c) => c.id),
      },
      contacts: {
        ...state.contacts,
        ...mapToObject(
          contacts,
          (c) => c.id,
          (c) => c,
        ),
      },
      messagePostId: postId,
    };
  }),
  on(SocialActions.LoadPostTranslation, (state, { postId }): State => {
    const content = state.postTranslations.entities[postId]?.content || null;
    return {
      ...state,
      activeTranslations: [...state.activeTranslations, postId],
      postTranslations: postTranslationsAdapter.upsertOne(
        { id: postId, status: TRANSLATION_STATUS.requesting, content },
        { ...state.postTranslations },
      ),
    };
  }),
  on(SocialActions.LoadPostTranslationSuccess, (state, { postId, content }): State => {
    return {
      ...state,
      postTranslations: postTranslationsAdapter.upsertOne(
        { id: postId, status: TRANSLATION_STATUS.translated, content },
        { ...state.postTranslations },
      ),
    };
  }),
  on(SocialActions.LoadPostTranslationFail, (state, { postId }): State => {
    return {
      ...state,
      postTranslations: postTranslationsAdapter.upsertOne(
        { id: postId, status: TRANSLATION_STATUS.error, content: null },
        { ...state.postTranslations },
      ),
    };
  }),
  on(SocialActions.RevertPostTranslation, (state, { postId }): State => {
    return {
      ...state,
      activeTranslations: [...state.activeTranslations.filter((t) => t !== postId)],
    };
  }),
  on(SocialActions.RevertAllPostTranslations, (state): State => {
    return {
      ...state,
      activeTranslations: [],
    };
  }),
  on(SocialActions.LoadSocialGroupsSuccess, (state, { groups }): State => {
    return {
      ...state,
      groups: groupsAdapter.upsertMany(groups, { ...state.groups }),
    };
  }),
  on(SocialActions.LoadSocialGroupsNewPostCountSuccess, (state, { newPostsPerGroup }): State => {
    return {
      ...state,
      newPostsPerGroup,
    };
  }),
  on(SocialActions.CreateGroupSuccess, (state, { group }): State => {
    return {
      ...state,
      groups: groupsAdapter.addOne(group, { ...state.groups }),
    };
  }),
  on(SocialActions.LeaveGroupSuccess, (state, { groupId }): State => {
    return {
      ...state,
      groups: groupsAdapter.removeOne(groupId, { ...state.groups }),
    };
  }),
  on(SocialActions.EditGroupSuccess, (state, { group }): State => {
    return {
      ...state,
      groups: groupsAdapter.upsertOne(group, { ...state.groups }),
    };
  }),
  on(SocialActions.LoadShareMessagePreview, (state, { messageId }): State => {
    return {
      ...state,
      shareAttachmentPreviews: {
        ...state.shareAttachmentPreviews,
        [messageId]: { loading: true, allGroupMembersHaveAccess: true },
      },
    };
  }),
  on(SocialActions.LoadShareMessagePreviewSuccess, (state, { messageId, data }): State => {
    return {
      ...state,
      shareAttachmentPreviews: {
        ...state.shareAttachmentPreviews,
        [messageId]: {
          ...{ data },
          loading: false,
          allGroupMembersHaveAccess: state.shareAttachmentPreviews[messageId].allGroupMembersHaveAccess,
        },
      },
    };
  }),
  on(SocialActions.LoadShareMessagePreviewFail, (state, { messageId, error }): State => {
    if (error?.error?.code === 'NO_ACCESS') {
      return {
        ...state,
        shareAttachmentPreviews: {
          ...state.shareAttachmentPreviews,
          [messageId]: { ...state.shareAttachmentPreviews[messageId], loading: false, hiddenToCurrentUser: true },
        },
      };
    } else if (error?.error?.code === 'ITEM_NOT_FOUND') {
      return {
        ...state,
        shareAttachmentPreviews: {
          ...state.shareAttachmentPreviews,
          [messageId]: { ...state.shareAttachmentPreviews[messageId], loading: false, messageNotFound: true },
        },
      };
    }
    return state;
  }),
  on(SocialActions.CheckGroupMembersAccess, (state, { messageId }): State => {
    return {
      ...state,
      shareAttachmentPreviews: {
        ...state.shareAttachmentPreviews,
        [messageId]: { ...state.shareAttachmentPreviews[messageId], loading: true, allGroupMembersHaveAccess: true },
      },
    };
  }),
  on(SocialActions.CheckGroupMembersAccessSuccess, (state, { messageId, allGroupMembersHaveAccess }): State => {
    return {
      ...state,
      shareAttachmentPreviews: {
        ...state.shareAttachmentPreviews,
        [messageId]: { ...state.shareAttachmentPreviews[messageId], loading: false, allGroupMembersHaveAccess },
      },
    };
  }),
  on(SocialActions.SavedPostUpdate, (state, { itemId }) => {
    return {
      ...state,
      posts: postsAdapter.updateOne({ id: itemId, changes: { saved: true } }, state.posts),
    };
  }),
  on(SocialActions.UnsavedPostUpdate, (state, { itemId }) => {
    const updatedMessages = postsAdapter.updateOne({ id: itemId, changes: { saved: false } }, state.posts);

    return {
      ...state,
      posts: updatedMessages,
    };
  }),
);

export function reducer(state: State | undefined, action: Action) {
  return socialReducer(state, action);
}
