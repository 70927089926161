import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { FeatureFlagService } from '@ao/common-ui';
import {
  CompanyContext,
  Contact,
  EmitterChannel,
  EmitterMessage,
  EmitterMessagePayload,
  FeatureFlags,
  InsightsValue,
  Language,
  Message,
  MessageListData,
  MessageNew,
  MessageTrackingAttributes,
  MsgModule,
  MsgModuleContactInfoViewer,
  MsgModuleFeedback,
  MsgModuleFeedbackData,
  MsgModuleFeedbackItem,
  MsgModuleLike,
  PendingMessage,
  RedirectResponse,
  Sidebar,
  Theme,
  UserContext,
} from '@ao/data-models';
import { environment } from '@ao/environments';
import { ProfileFacade } from '@ao/profile-store';
import { DEFAULT_THEME_ID } from '@ao/shared-constants';
import { BrowserService, EMITTER, Emitter, getModuleRef, getThresholdStatus } from '@ao/utilities';
import { ViewerCoreFacade } from '@ao/viewer-core';
import { NotificationsFacade } from '@ao/viewer/notification-store';
import { Observable, fromEvent, of, throwError } from 'rxjs';
import { catchError, debounceTime, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { AppFacade } from '../app-store.facade';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  constructor(
    private http: HttpClient,
    private browserService: BrowserService,
    private appFacade: AppFacade,
    private viewerCoreFacade: ViewerCoreFacade,
    private profileFacade: ProfileFacade,
    @Inject(EMITTER) private emitter: Emitter,
    private notificationsFacade: NotificationsFacade,
    private featureFlagService: FeatureFlagService,
  ) {}

  getMessage(
    origin: string,
    keycode: string,
    queryParams: { [name: string]: string } = {},
  ): Observable<{
    message?: MessageNew;
    modules?: MsgModule[];
    theme?: Theme;
    sidebar?: Sidebar;
    pendingRecurringMsgs?: PendingMessage[];
    trackingAttributes?: MessageTrackingAttributes;
    redirectUrl?: string;
    contactInfo?: Contact;
    saved?: boolean;
  }> {
    const verify = !origin && keycode === 'verify';
    // We have to check if browser supports push notifications - to toggle setting it all up or not
    const url = `${environment.apiBaseUrl}/api/v1/viewer/${
      verify ? 'verify' : `${origin}/${keycode}`
    }?allowsPushNotifications=${this.allowsPushNotifications()}`;
    return this.http.get<Message>(url, { withCredentials: true, params: queryParams }).pipe(
      map((res) => {
        if (res.redirect?.url) {
          return { redirectUrl: res.redirect.url };
        }

        const { message, modules } = this.mapMessageToMessageNew(res, keycode);

        return {
          message,
          modules,
          theme: this.cleanupTheme(res.theme?.id ? res.theme : null),
          sidebar: message.sidebar || null,
          pendingRecurringMsgs: res.pendingRecurringMsgs,
          trackingAttributes: res.trackingAttributes,
          contactInfo: res.contact,
          saved: res.settings.saved,
        };
      }),
      catchError((error: any) => throwError(() => error)),
    );
  }

  cleanupTheme(theme: Theme): Theme {
    if (!theme) {
      return theme;
    }
    const checkProp = (prop: string) =>
      theme.font?.[prop] && Object.keys(theme.font?.[prop]).length > 0 && theme.font?.[prop].constructor === Object;
    return {
      ...theme,
      font:
        checkProp('regular') || checkProp('italic') || checkProp('boldItalic') || checkProp('bold') ? theme.font : null,
    };
  }

  getAllThemes(keycode: string): Observable<Theme[]> {
    const url = `${environment.apiBaseUrl}/api/v1/viewer/${keycode}/themes`;
    return this.http
      .get<{ data: Theme[] }>(url, { withCredentials: true })
      .pipe(map(({ data }) => data.map(this.cleanupTheme)));
  }

  goToMessageUrl(keycode: string, messageId: number, queryParams: { [name: string]: string } = {}): string {
    return `${environment.apiBaseUrl}/api/v1/messages/redirect/${keycode}/${messageId}?${this.buildQueryParams({
      ...queryParams,
      redirect: 'true',
    })}`;
  }

  getKeycodeForMessage(keycode: string, messageId: number, queryParams: { [name: string]: string } = {}) {
    const url = `${environment.apiBaseUrl}/api/v1/messages/redirect/${keycode}/${messageId}`;
    return this.http.get<RedirectResponse>(url, { params: queryParams });
  }

  goToAcademyUrl(keycode: string, academyId: number, queryParams: { [name: string]: string } = {}): string {
    return `${environment.apiBaseUrl}/api/v1/academy/redirect/${keycode}/${academyId}?${this.buildQueryParams({
      ...queryParams,
      redirect: 'true',
    })}`;
  }

  getKeycodeForAcademy(keycode: string, academyId: number, queryParams: { [name: string]: string } = {}) {
    const url = `${environment.apiBaseUrl}/api/v1/academy/redirect/${keycode}/${academyId}`;
    return this.http
      .get<RedirectResponse>(url, { params: queryParams })
      .pipe(catchError((error: any) => throwError(error)));
  }

  retakeQuiz(keycode: string) {
    const url = `${environment.apiBaseUrl}/api/v1/viewer/${keycode}/retakeQuiz`;
    return this.http.post<MsgModuleFeedbackItem>(url, null).pipe(catchError((error: any) => throwError(error)));
  }

  getAppContext(
    origin: string,
    keycode: string,
    queryParams: Record<string, string> = {},
  ): Observable<{
    companyContext?: CompanyContext;
    userContext?: UserContext;
    message?: MessageNew;
    modules?: MsgModule[];
    theme?: Theme;
    sidebar?: Sidebar;
    redirectUrl?: string;
    saved?: boolean;
  }> {
    const verify = !origin && keycode === 'verify';
    // We have to check if browser supports push notifications - to toggle setting it all up or not
    const url = `${environment.apiBaseUrl}/api/v1/viewer/${
      verify ? 'verify' : `${origin}/${keycode}`
    }?allowsPushNotifications=${this.allowsPushNotifications()}`;
    return this.http.get<Message>(url, { withCredentials: true, params: queryParams }).pipe(
      map((serverMessage) => {
        if (serverMessage?.redirect?.url) {
          return { redirectUrl: serverMessage?.redirect.url };
        }

        const { message, modules } = this.mapMessageToMessageNew(serverMessage, keycode);

        const clientFeatureFlags = Object.keys(serverMessage.clientFeatureFlags).reduce((flags, key) => {
          return {
            ...flags,
            [key]: Boolean(serverMessage.clientFeatureFlags[key]),
          };
        }, {} as FeatureFlags);
        this.featureFlagService.setFeatureFlags(clientFeatureFlags);
        return {
          companyContext: {
            socialEnabled: serverMessage.social?.active || false,
            socialName: serverMessage.social?.name || '',
            directoryEnabled: serverMessage.settings?.employee_directory || false,
            autoTranslationEnabled: serverMessage.settings?.auto_translations || false,
            chatEnabled: serverMessage.settings?.chatEnabled || false,
            searchEnabled: serverMessage.settings?.searchEnabled || false,
            saveContentEnabled: serverMessage.settings?.saveContentEnabled || false,
            taskManagementEnabled: serverMessage.settings?.taskManagementEnabled || false,
            termsOfUse: {
              enabled: serverMessage.termsOfUse?.enabled || false,
              customText: serverMessage.termsOfUse?.customText || '',
            },
            nativeApp: {
              enabled: serverMessage.nativeApp?.enabled || false,
              smartBanner: serverMessage.nativeApp?.smartBanner || false,
              appName: serverMessage.nativeApp?.appName || '',
            },
            clientFeatureFlags,
            supportSettings: serverMessage.supportSettings,
            emitterChannels: [],
            insights: serverMessage.insights,
            customFields: serverMessage.customFields,
            menuExpandedDesktop: serverMessage.menuExpandedDesktop,
            defaultLanguage: serverMessage.defaultLanguage,
            trackingAttributes: serverMessage.trackingAttributes,
            s3UploadEnabled: serverMessage.s3UploadEnabled,
          },
          userContext: {
            contact: {
              ...serverMessage.contact,
              contact_status: {
                ...serverMessage.contact?.contact_status,
                lastSeenNotifications: new Date(serverMessage.contact?.contact_status?.lastSeenNotifications || 0),
              },
            },
            chatHasUnread: false,
            unreadCounts: {},
            contactAuthCode: serverMessage.contactAuthCode,
            relationships: serverMessage.relationships,
            employees: serverMessage.employees,
            country: serverMessage.country,
            profileSettings: serverMessage.profileSettings,
            pendingRecurringMsgs: serverMessage.pendingRecurringMsgs,
            insightsData: [],
          },
          message,
          modules,
          theme: this.cleanupTheme(serverMessage.theme?.id ? serverMessage.theme : null),
          sidebar: message.sidebar || null,
          saved: message.saved,
        };
      }),
      catchError((error: any) => throwError(error)),
    );
  }

  getInsightValues(
    targetId: number,
    sourceId: number | null,
    sourceAuthCode: string,
    messageId: number,
  ): Observable<InsightsValue[]> {
    const params: { [name: string]: any } = {
      sourceId,
      sourceAuthCode,
      messageId,
    };
    const url = `${environment.apiBaseUrl}/api/v1/contacts/${targetId}/insights/all`;
    return this.http.get<{ data: InsightsValue[] }>(url, { params }).pipe(
      map((res) => {
        // iterate the "traffic light status" of profile's insights
        const insightsValues: InsightsValue[] = res.data.map((insight) => {
          return {
            ...insight,
            status: getThresholdStatus(insight.value, insight.upperThreshold, insight.lowerThreshold),
          };
        });
        return insightsValues;
      }),
      catchError((error: any) => throwError(error)),
    );
  }

  emitterSetup() {
    const emitterMessage$ = fromEvent(this.emitter, 'message');

    emitterMessage$
      .pipe(
        map((message: EmitterMessage) => message.asObject()),
        filter(({ event }: EmitterMessagePayload) => {
          return event === 'updateContact';
        }),
        switchMap(({ event, data }: EmitterMessagePayload) => {
          return of({ event, data }).pipe(
            withLatestFrom(this.viewerCoreFacade.keycode$, this.viewerCoreFacade.contact$),
          );
        }),
      )
      .subscribe(([{ data }, keycode, contact]) => {
        if (data?.id === contact.id && !contact.updateInProgress) {
          this.profileFacade.loadProfile({ profileId: data.id });
          this.viewerCoreFacade.updateContact(keycode, data.id);
          return;
        }
      });

    emitterMessage$
      .pipe(
        map((message: EmitterMessage) => message.asObject()),
        filter(({ event }: EmitterMessagePayload) => {
          return event === 'updateInsights';
        }),
        debounceTime(1000),
      )
      .subscribe(({ data }: EmitterMessagePayload) => {
        const contactId: number = data.id;
        this.profileFacade.updateProfileInsights({ contactId });
        this.viewerCoreFacade.loadInsights();
      });

    emitterMessage$
      .pipe(
        map((message: EmitterMessage) => message.asObject()),
        filter(({ event }: EmitterMessagePayload) => event === 'notificationCountChanged'),
        debounceTime(1000),
      )
      .subscribe(() => {
        this.notificationsFacade.loadNotificationHasUnread(true);
      });

    emitterMessage$
      .pipe(
        map((message: EmitterMessage) => message.asObject()),
        filter(
          ({ event }: EmitterMessagePayload) =>
            !['notificationCountChanged', 'updateInsights', 'updateContact'].includes(event),
        ),
        switchMap((message: EmitterMessagePayload) => {
          const { event, data, module: moduleRef } = message;
          return of({ event, data }).pipe(withLatestFrom(this.appFacade.moduleByModuleRef$(moduleRef)));
        }),
      )
      .subscribe(([{ event, data }, module]) => {
        if (module && module.type === 'feedback' && event === 'feedback' && module.public) {
          this.appFacade.feedbackUpdate({
            module: <MsgModuleFeedback>module,
            comment: {
              name: data.name,
              id: data.id,
              content: data.content,
              created_at: data.date,
            },
          });
          return;
        }

        if (module && module.type === 'like' && event === 'click') {
          this.appFacade.likeUpdate({
            module: <MsgModuleLike>module,
            contact: data.contact,
          });
          return;
        }
      });
  }

  emitterSubscribe(channels: EmitterChannel[]) {
    if (!channels || !channels.length) {
      return null;
    }
    for (const channel of channels) {
      this.emitter.subscribe({
        channel: channel.channel,
        key: channel.key,
      });
    }
    return channels;
  }

  emitterUnsubscribe(channels: EmitterChannel[]) {
    if (!channels || !channels.length) {
      return null;
    }
    for (const channel of channels) {
      this.emitter.unsubscribe({
        channel: channel.channel,
        key: channel.key,
      });
    }
    return null;
  }

  submitContactInfo(
    module: MsgModuleContactInfoViewer,
    contactData: any,
    messageData: { messageId: number; origin: string },
    eventType: string,
  ) {
    if (eventType === 'update') {
      return this.http
        .put(`${environment.apiBaseUrl}/api/v1/contacts/${contactData.contactId}/from-module`, {
          ...contactData.data,
          module_id: module.id,
          modular_message_id: messageData.messageId,
          source: messageData.origin,
          auth_code: module.auth_code,
        })
        .pipe(
          catchError((error: any) => {
            return throwError(error);
          }),
        );
    } else {
      return this.http
        .post(`${environment.apiBaseUrl}/api/v1/contacts/from-module`, {
          ...contactData.data,
          module_id: module.id,
          modular_message_id: messageData.messageId,
          source: messageData.origin,
          auth_code: module.auth_code,
        })
        .pipe(
          catchError((error: any) => {
            return throwError(error);
          }),
        );
    }
  }

  getChartData(dataUrl: string): Observable<any> {
    return this.http.get(dataUrl).pipe(catchError((error: any) => throwError(error)));
  }

  loadFeedback(keycode: string, moduleId: any): Observable<MsgModuleFeedbackData> {
    const url = `${environment.apiBaseUrl}/api/v1/viewer/${keycode}/feedback/${moduleId}`;
    return this.http.get<MsgModuleFeedbackData>(url).pipe(catchError((error: any) => throwError(error)));
  }

  submitFeedback(keycode: string, moduleId: string, content: string): Observable<MsgModuleFeedbackItem> {
    const url = `${environment.apiBaseUrl}/api/v1/messages/${keycode}/feedback/${moduleId}`;
    return this.http.post(url, { content: content }).pipe(
      map((res: any) => res.data[0]),
      catchError((error: any) => throwError(error)),
    );
  }

  deleteFeedback(keycode: string, moduleId: number): Observable<MsgModuleFeedbackItem> {
    const url = `${environment.apiBaseUrl}/api/v1/messages/${keycode}/feedback/${moduleId}`;
    return this.http.delete(url).pipe(
      map((res: any) => res.data[0]),
      catchError((error: any) => throwError(error)),
    );
  }

  getMessageListData(
    keycode: string,
    moduleId: number,
    params: { [name: string]: any } = {},
  ): Observable<MessageListData> {
    const url = `${environment.apiBaseUrl}/api/v1/messages/${keycode}/messagelist/${moduleId}`;
    return this.http.get<MessageListData>(url, { params }).pipe(catchError((error: any) => throwError(() => error)));
  }

  trackEvent(data: any) {
    const url = `${environment.apiBaseUrl}/api/v1/event/register`;
    return this.http
      .request('POST', url, {
        body: data,
        headers: { 'Content-Type': 'text/plain' },
      })
      .pipe(
        map((res: any) => res?.data),
        catchError((error: any) => throwError(error)),
      );
  }

  verifySms(phoneNumber: string, smsId: string) {
    const url = `${environment.apiBaseUrl}/api/v1/messages/sms/verify/${phoneNumber}/${smsId}`;
    return this.http.get(url).pipe(catchError((error: any) => throwError(error)));
  }

  loadAutoTranslationLangs(keycode: string): Observable<Language[]> {
    const url = `${environment.apiBaseUrl}/api/v1/autoTranslations/${keycode}/supportedLanguages`;
    return this.http.get<{ data: Language[] }>(url).pipe(
      map((res) => res.data),
      catchError((error: any) => throwError(error)),
    );
  }

  loadTranslation(keycode: string, academyId?: number): Observable<Record<string, string>> {
    const url = `${environment.apiBaseUrl}/api/v1/viewer/${keycode}/translations`;
    return this.http.get<{ data: Record<string, string> }>(url).pipe(
      map((res) => {
        if (academyId) {
          return Object.entries(res.data).reduce((acc, [key, value]) => {
            return { ...acc, [`academy:${academyId}:${key}`]: value };
          }, {});
        }
        return res.data;
      }),
      catchError((error: any) => throwError(error)),
    );
  }

  private mapMessageToMessageNew(
    serverMessage: Message,
    keycode: string,
  ): { message: MessageNew; modules: MsgModule[] } {
    const pages = serverMessage.pages.map((page) => {
      return {
        ...page,
        modules: page.modules.map((m) => getModuleRef(m)),
      };
    });
    const modules = serverMessage.pages.reduce((acc, cur) => {
      return [...acc, ...cur.modules];
    }, []);

    const message: MessageNew = {
      keycode,
      id: serverMessage.settings?.id,
      academy: serverMessage.academy,
      training: serverMessage.training,
      allowUnsubscribe: false, // coming from BE, but not in store currently
      themeId: serverMessage.theme?.id || DEFAULT_THEME_ID,
      sidebar: serverMessage.sidebar || null,
      sidebarId: serverMessage.sidebar?.id || null,
      pages,
      insights: serverMessage.insights,
      statpack: serverMessage.statpack,
      subject: serverMessage.subject,
      messageState: serverMessage.messageState,
      context: serverMessage.context,
      pagination: serverMessage.pagination,
      authentication: serverMessage.authentication,
      questionnaireRetake: false,
      questionnaire: {},
      disablePushNotifications: serverMessage.disablePushNotifications,
      auth: serverMessage.auth,
      redirect: serverMessage.redirect,
      translatable: serverMessage.settings?.translatable,

      user_id: serverMessage.settings?.user_id,
      client_id: serverMessage.settings?.client_id,
      type: serverMessage.settings?.type,
      quiz_retake_interval: serverMessage.settings?.quiz_retake_interval,
      quiz_retake_max_attempts: serverMessage.settings?.quiz_retake_max_attempts,
      quiz_retake_message: serverMessage.settings?.quiz_retake_message,
      content_language: serverMessage.settings?.content_language,
      allow_unsubscribe: serverMessage.settings?.allow_unsubscribe,
      add_to_homepage: serverMessage.settings?.add_to_homepage,
      active: serverMessage.settings?.active,
      anonymous: serverMessage.settings?.anonymous,
      // origin: serverMessage.settings?.origin,
      // publish_in_app: serverMessage.settings?.publish_in_app,
      viewer_auth_enabled: serverMessage.settings?.viewer_auth_enabled,
      likes_enabled: serverMessage.settings?.likes_enabled,
      comments_enabled: serverMessage.settings?.comments_enabled,
      message_sharing_enabled: serverMessage.settings?.message_sharing_enabled,
      postId: serverMessage.settings?.postId,
      comments_page: serverMessage.settings?.comments_page,
      isTest: serverMessage.settings?.isTest,
      quizData: serverMessage.quizData,
      emitterChannel: serverMessage.settings?.emitterChannel,
      client_notification_settings: serverMessage.settings?.client_notification_settings,
      saved: serverMessage.settings?.saved,
    };
    return { message, modules };
  }

  private buildQueryParams(queryParams: { [name: string]: string }) {
    return Object.keys(queryParams)
      .map(function (key) {
        return [key, queryParams[key]].map(encodeURIComponent).join('=');
      })
      .join('&');
  }

  private allowsPushNotifications() {
    return (
      navigator &&
      'serviceWorker' in navigator &&
      window &&
      'Notification' in window &&
      !this.browserService.isMobileSafari()
    );
  }
}
