<div class="ao-category-tags__categories">
  <span class="ao-category-tags__category" *ngFor="let category of categories | slice: 0 : limit; trackBy: trackByName">
    <div
      *ngIf="category.icon"
      class="ao-category-tags__category--icon-container"
      [style.background-color]="iconBackgroundColor"
    >
      <ao-icon [size]="12" [name]="category.icon"></ao-icon>
    </div>
    <span class="ao-font-tiny-bold ao-category-tags__category-label" [innerHTML]="category.name"></span>
  </span>
  <span
    [aoTooltip]="tAvailability"
    [position]="'top'"
    (click)="stopPropagation($event)"
    class="ao-font-tiny-bold ao-category-tags__more-categories"
    *ngIf="remainingCategories"
  >
    + {{ categories.length - limit }}
  </span>
  <ao-tooltip #tAvailability>
    <div class="ao-font-tiny-bold ao-category-tags__more-categories__tooltip">
      {{ remainingCategories }}
    </div>
  </ao-tooltip>
</div>
