import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { WINDOW } from '@ao/utilities';

@Component({
  selector: 'ao-highcharts',
  template: '',
  styles: [':host { display: block }'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HighchartsComponent implements OnChanges, AfterViewInit {
  chart: any;
  @Input() options;
  @Input() oneToOne = false;

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() load = new EventEmitter<any>();

  constructor(
    private element: ElementRef,
    @Inject(WINDOW) private window: Window,
  ) {}

  async ngAfterViewInit() {
    if (!window.Highcharts) {
      const Highcharts = await import('highcharts');
      const HighchartsMore = await import('highcharts/highcharts-more');
      HighchartsMore.default(Highcharts);
      window.Highcharts = Highcharts;
    }

    this.window.requestAnimationFrame(() => {
      if (this.options) {
        this.updateOrCreateChart(this.options);
      }
    });
  }

  ngOnChanges(c: SimpleChanges) {
    if (this.chart) {
      this.window.requestAnimationFrame(() => {
        this.updateOrCreateChart(this.options);
      });
    }
  }

  updateOrCreateChart(options) {
    if (this.chart && this.chart.update) {
      this.chart.update(options, true, this.oneToOne);
    } else {
      if (window.Highcharts) {
        this.chart = window.Highcharts.chart(this.element.nativeElement, options, (chart) => this.load.emit(chart));
      }
    }
  }
}
