import { A11yModule } from '@angular/cdk/a11y';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { HammerModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { CommonUiModule } from '@ao/common-ui';
import { UtilitiesModule } from '@ao/utilities';
import { TranslateModule } from '@ngx-translate/core';
import { AddToHomescreenComponent } from './components/add-to-homescreen/add-to-homescreen.component';
import { AppMediaUploadComponent } from './components/app-media-upload/app-media-upload.component';
import { AutoTranslationModalComponent } from './components/auto-translation-modal/auto-translation-modal.component';
import { AutoTranslationOnboardingModalComponent } from './components/auto-translation-onboarding-modal/auto-translation-onboarding-modal.component';
import { BadgeIconComponent } from './components/badge-icon/badge-icon.component';
import { BadgeComponent } from './components/badge/badge.component';
import { CloseModalButtonComponent } from './components/closeModalButton/close-modal-button.component';
import { CompleteTrainingFooterComponent } from './components/complete-training-footer/complete-training-footer.component';
import { ContactGroupsListComponent } from './components/contact-groups-list/contact-groups-list.component';
import { DownloadUploadedFileComponent } from './components/download-uploaded-file/download-uploaded-file.component';
import { GenericPageHeaderComponent } from './components/generic-page-header/generic-page-header.component';
import { GenericViewerErrorComponent } from './components/generic-viewer-error/generic-viewer-error.component';
import { HeaderComponent } from './components/header/header.component';
import { InsightsTitleComponent } from './components/insights-title/insights-title.component';
import { InsightsValueComponent } from './components/insights-value/insights-value.component';
import { LightboxModalComponent } from './components/lightbox-modal/lightbox-modal.component';
import { LoginAuthComponent } from './components/login-auth/login-auth.component';
import { HomepagelistItemComponent } from './components/login-client-picker/homepagelist-item/homepagelist-item.component';
import { LoginClientPickerComponent } from './components/login-client-picker/login-client-picker.component';
import { LoginComponent } from './components/login/login.component';
import { MenuButtonComponent } from './components/menu-button/menu-button.component';
import { ModuleInsightsComponent } from './components/module-insights/module-insights.component';
import { NavBarContentComponent } from './components/nav-bar-content/nav-bar-content.component';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { NotificationListItemComponent } from './components/notification-list-item/notification-list-item.component';
import { NotificationListSkeletonComponent } from './components/notification-list/notification-list-skeleton/notification-list-skeleton.component';
import { NotificationListComponent } from './components/notification-list/notification-list.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { PeerViewMainInfoComponent } from './components/peer-view-main-info/peer-view-main-info.component';
import { PeerViewComponent } from './components/peer-view/peer-view.component';
import { PendingAssignmentBannerComponent } from './components/pending-assignment-banner/pending-assignment-banner.component';
import { PinCodeInputComponent } from './components/pin-code-input/pin-code-input.component';
import { PreviewInfoTopHeaderComponent } from './components/preview-info-top-header/preview-info-top-header.component';
import { ProgressComponent } from './components/progress/progress.component';
import { RatingHeaderComponent } from './components/rating-header/rating-header.component';
import { SectionHeaderComponent } from './components/section-header/section-header.component';
import { ShellComponent } from './components/shell/shell.component';
import { SidebarContentComponent } from './components/sidebar-content/sidebar-content.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { SocialGroupItemComponent } from './components/social-group-item/social-group-item.component';
import { SsoLoginComponent } from './components/sso-login/sso-login.component';
import { SupportModalComponent } from './components/support-modal/support-modal.component';
import { ViewerToastComponent } from './components/toast/toast.component';
import { UpdateContactInfoComponent } from './components/update-contact-info/update-contact-info.component';
import { UploadedAttachmentComponent } from './components/uploaded-attachment/uploaded-attachment.component';
import { ViewerBackdropComponent } from './components/viewer-backdrop/viewer-backdrop.component';
import { ViewerErrorPageComponent } from './components/viewer-error-page/viewer-error-page.component';
import {
  ViewerInputWrapperComponent,
  ViewerInputWrapperDisabledTemplateDirective,
  ViewerInputWrapperErrorTemplateDirective,
  ViewerInputWrapperLabelTemplateDirective,
  ViewerInputWrapperTooltipTemplateDirective,
} from './components/viewer-input-wrapper/viewer-input-wrapper.component';
import { PeerViewService } from './services/peer-view.service';

// components used only within viewer-ui
const COMPONENTS = [PinCodeInputComponent];

// components exported into other modules
const EXPORT_COMPONENTS = [
  BadgeComponent,
  ModuleInsightsComponent,
  HomepagelistItemComponent,
  LoginAuthComponent,
  InsightsValueComponent,
  InsightsTitleComponent,
  PaginationComponent,
  SidebarComponent,
  SidebarContentComponent,
  HeaderComponent,
  ShellComponent,
  ProgressComponent,
  SectionHeaderComponent,
  MenuButtonComponent,
  AddToHomescreenComponent,
  RatingHeaderComponent,
  BadgeIconComponent,
  PeerViewComponent,
  PeerViewMainInfoComponent,
  CloseModalButtonComponent,
  CompleteTrainingFooterComponent,
  LoginComponent,
  SsoLoginComponent,
  SupportModalComponent,
  ViewerToastComponent,
  LoginClientPickerComponent,
  GenericPageHeaderComponent,
  GenericViewerErrorComponent,
  ViewerErrorPageComponent,
  UpdateContactInfoComponent,
  ViewerInputWrapperComponent,
  ViewerInputWrapperTooltipTemplateDirective,
  ViewerInputWrapperErrorTemplateDirective,
  ViewerInputWrapperDisabledTemplateDirective,
  ViewerInputWrapperLabelTemplateDirective,
  AutoTranslationModalComponent,
  NotificationListComponent,
  NotificationListSkeletonComponent,
  NotificationListItemComponent,
  AutoTranslationOnboardingModalComponent,
  SocialGroupItemComponent,
  NavBarComponent,
  NavBarContentComponent,
  BadgeComponent,
  PreviewInfoTopHeaderComponent,
  ViewerBackdropComponent,
  LightboxModalComponent,
  DownloadUploadedFileComponent,
  PendingAssignmentBannerComponent,
  AppMediaUploadComponent,
  UploadedAttachmentComponent,
  ContactGroupsListComponent,
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    CommonUiModule,
    OverlayModule,
    ReactiveFormsModule,
    HammerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSidenavModule,
    MatSnackBarModule,
    TranslateModule,
    UtilitiesModule,
    ScrollingModule,
    RouterModule,
    PortalModule,
    A11yModule,
  ],
  declarations: [...COMPONENTS, ...EXPORT_COMPONENTS],
  exports: [...COMPONENTS, ...EXPORT_COMPONENTS],
  providers: [PeerViewService],
})
export class InternalViewerUiModule {}

@NgModule({
  imports: [InternalViewerUiModule],
  exports: [EXPORT_COMPONENTS],
})
export class ViewerUiModule {}
