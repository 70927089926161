import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { WINDOW, onceDefined, onceWithLatest } from '@ao/utilities';
import { ViewerCoreFacade } from '@ao/viewer-core';
import { map } from 'rxjs';

export const savedContentGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const viewerCoreFacade = inject(ViewerCoreFacade);
  const _window: Window = inject(WINDOW);

  return viewerCoreFacade.saveContentEnabled$.pipe(
    onceDefined(),
    map((saveContentEnabled) => {
      if (!saveContentEnabled) {
        return router.parseUrl(`/login?redirectPath=${_window.location.pathname}`);
      }
      return saveContentEnabled ? true : router.parseUrl(`/login`);
    }),
  );
};
