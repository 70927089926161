import { PaginationSchema } from '../common';
import { z } from 'zod';

const IDSchema = z.string().pipe(z.coerce.number().min(1)); // redefined from @ao-utils/zodValidate, but importing it causes circular dependency :/
const IDSchemaNumber = z.number().min(1);

const moduleTypes = [
  'video',
  'text',
  'buttongroup',
  'feedback',
  'image',
  'like',
  'audio',
  'slide', // "presentation"
  'slider',
  'youtube',
  'datapicker',
  'upload',
  'chart',
  'messagelist',
  'socialFeed',
  'embed',
  'grid',
  'confirm',
  'kahootchallenge',
  'media',
  // used?
  'contactinfo',
  'pagebreak',
  'unknown',
  'channelPreferenc',
  'optInOut',
  'auth',
  'smsverify',
  'group',
  'post',
  'homepage',
] as const;

const ModuleTypeSchema = z.enum(moduleTypes);
export type ModuleType = z.output<typeof ModuleTypeSchema>;

const eventTypes = [
  'view',
  'click',
  'upload',
  'confirmed',
  'slider_0',
  'slider_1',
  'slider_2',
  'slider_3',
  'slider_4',
  'slider_5',
  'slider_6',
  'slider_7',
  'slider_8',
  'slider_9',
  'slider_10',
  'play0',
  'play10',
  'play20',
  'play30',
  'play40',
  'play50',
  'play60',
  'play70',
  'play80',
  'play90',
  'play100',
  'create',
  'send',
  'update',
  'submit',
] as const;

const EventTypeSchema = z.enum(eventTypes);
export type EventType = z.output<typeof EventTypeSchema>;

const orderByTypes = ['name', 'email', 'phoneNumber', 'department'] as const;

const OrderByTypeSchema = z.enum(orderByTypes);
export type OrderByType = z.output<typeof OrderByTypeSchema>;

const orderDirTypes = ['asc', 'desc'] as const;

const OrderDirTypeSchema = z.enum(orderDirTypes);
export type OrderDirType = z.output<typeof OrderDirTypeSchema>;

/**
 * Schema of GET /messages/:id/analytics/modules/:moduleType/:moduleId/:submoduleId/:event
 */
export const GetModuleAnalyticsInputSchema = z.intersection(
  PaginationSchema,
  z.object({
    clientId: IDSchemaNumber,
    projectId: IDSchemaNumber,
    messageId: IDSchema,
    moduleType: ModuleTypeSchema,
    moduleId: IDSchema,
    submoduleId: IDSchemaNumber.nullable(),
    event: EventTypeSchema,
    inverse: z.enum(['true', 'false']).transform((value) => value === 'true'),
    search: z.string().optional(),
    orderDir: z.enum(orderDirTypes).default('asc'),
    orderBy: z.enum(orderByTypes).default('name'),
  }),
);

export type GetModuleAnalyticsInputSchemaQueryInput = z.input<typeof GetModuleAnalyticsInputSchema>;
export type GetModuleAnalyticsInputSchemaParsedInput = z.output<typeof GetModuleAnalyticsInputSchema>;
