import { GATrackingData, Insights, MessageNew, MsgModule, Page, TRANSLATION_STATUS } from '@ao/data-models';
import { DEFAULT_THEME_ID } from '@ao/shared-constants';
import { getModuleRef, getRouteDataPage, selectRouteNestedParam } from '@ao/utilities';
import { viewerCoreSelectors } from '@ao/viewer-core';
import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectHasNavigatedToMessage } from 'libs/viewer/viewer-core/src/lib/store/+state/selectors/router-history.selectors';
import { selectTrackingAttributes } from 'libs/viewer/viewer-core/src/lib/store/+state/selectors/viewer-core-store.selectors';
import * as fromApp from '../reducers/app-store.state';

export const selectAppState = createFeatureSelector<fromApp.AppState>(fromApp.APP_FEATURE_KEY);

export const selectErrorState = createSelector(selectAppState, (state) => state.error);
export const selectEmitterChannels = createSelector(selectAppState, (state) => state.emitterChannels);
export const selectAutoTranslationLangs = createSelector(selectAppState, (state) => state.autoTranslateLangs);
export const selectShowAutoTranslationModal = createSelector(selectAppState, (state) => state.showAutoTranslationModal);
export const selectAutoTranslationStatus = createSelector(selectAppState, (state) => state.autoTranslationStatus);

export const selectErrorCode = createSelector(selectErrorState, (state) => state?.code);
export const selectErrorLanguage = createSelector(selectErrorState, (state) => state?.contentLanguage);
export const selectErrorSupport = createSelector(selectErrorState, (state) => state?.loginConfig?.config?.support);
export const selectErrorTheme = createSelector(selectErrorState, (state) => state?.loginConfig?.theme);

// Messages
export const selectMessageEntities = createSelector(selectAppState, (state) =>
  fromApp.messageAdapter.getSelectors().selectEntities(state.messages),
);
export const selectCurrentMessage = createSelector(
  selectMessageEntities,
  viewerCoreSelectors.selectCurrentKeycode,
  (entities, messageId) => {
    const message = entities?.[messageId] as MessageNew;
    return message || null;
  },
);
export const selectCurrentMessageIsSaved = createSelector(selectCurrentMessage, (state) => state?.saved);
export const selectCurrentMessageId = createSelector(selectCurrentMessage, (state) => state?.id);
export const selectIsTest = createSelector(selectCurrentMessage, (state) => state?.isTest);
export const selectIsAcademy = createSelector(selectCurrentMessage, (state) => !!state?.academy);
export const selectCurrentMessageSubject = createSelector(selectCurrentMessage, (state) => state?.subject);
export const selectCurrentMessageKeycode = createSelector(selectCurrentMessage, (state) => state?.keycode);
export const selectMessageAuthentication = createSelector(selectCurrentMessage, (state) => state?.authentication);
export const selectMessageAuth = createSelector(selectCurrentMessage, (state) => state?.auth);
export const selectMessageContext = createSelector(selectCurrentMessage, (state) => state?.context);
export const selectMessageType = createSelector(selectCurrentMessage, (state) => state?.type);
export const selectMessageTraining = createSelector(selectCurrentMessage, (state) => state?.training);
export const selectMessagePagination = createSelector(selectCurrentMessage, (state) => state?.pagination);
export const selectMessageState = createSelector(selectCurrentMessage, (state) => state?.messageState);
export const selectMessageQuizData = createSelector(selectCurrentMessage, (state) => state?.quizData);
export const selectMessageStatpack = createSelector(selectCurrentMessage, (state) => state?.statpack);
export const selectMessageClientId = createSelector(selectCurrentMessage, (state) => state?.client_id);
export const selectMessageQuestionnaire = createSelector(selectCurrentMessage, (state) => state?.questionnaire);
export const selectMessageQuestionnaireRetake = createSelector(
  selectCurrentMessage,
  (state) => state?.questionnaireRetake,
);
export const selectMessageQuestionnaireReady = createSelector(
  selectMessageQuestionnaire,
  (state) => state?.__rehydrated,
);
export const selectMessageAddToHomepage = createSelector(selectCurrentMessage, (state) => state?.add_to_homepage);
export const selectDisablePushNotifications = createSelector(
  selectCurrentMessage,
  (state) => state?.disablePushNotifications,
);
export const selectMessageContentLanguage = createSelector(selectCurrentMessage, (state) => state?.content_language);
export const selectMessageViewerAuthEnabled = createSelector(
  selectCurrentMessage,
  (state) => state?.viewer_auth_enabled,
);
export const selectMessageCommentsEnabled = createSelector(selectCurrentMessage, (state) => state?.comments_enabled);
export const selectMessageReactionsEnabled = createSelector(selectCurrentMessage, (state) => state?.likes_enabled);
export const selectMessageShareEnabled = createSelector(
  selectCurrentMessage,
  (state) => state?.message_sharing_enabled,
);

export const selectMessageQuizRetakeMax = createSelector(
  selectCurrentMessage,
  (state) => state?.quiz_retake_max_attempts,
);
export const selectMessageQuizRetakeInterval = createSelector(
  selectCurrentMessage,
  (state) => state?.quiz_retake_interval,
);
export const selectMessageAnonymous = createSelector(selectCurrentMessage, (state) => state?.anonymous);
export const selectMessageTranslatable = createSelector(selectCurrentMessage, (state) => state?.translatable);
export const selectMessageAllowUnsubscribe = createSelector(selectCurrentMessage, (state) => state?.allow_unsubscribe);
export const selectCurrentMessagePages = createSelector(selectCurrentMessage, (currentMessage) => {
  return currentMessage?.pages || [];
});
export const getSelectedMessagePostId = createSelector(selectCurrentMessage, (state) => state?.postId);
export const selectPushEnabledByClient = createSelector(selectCurrentMessage, (state) => {
  const publishedContent = state?.client_notification_settings.publishedContent.push;
  const social = state?.client_notification_settings.social.push;
  return publishedContent.default || publishedContent.canOverride || social.default || social.canOverride;
});
export const selectCurrentMessagePage = createSelector(
  selectCurrentMessagePages,
  viewerCoreSelectors.selectCurrentPageId,
  (pages, pageId) => {
    return pages[Math.max(pageId - 1, 0)];
  },
);

export const selectInsights = createSelector(
  selectCurrentMessage,
  (messageContext) => messageContext?.insights || ({ active: false, settings: {} } as Insights),
);

export const selectCurrentAutoTranslationStatus = createSelector(
  selectAutoTranslationStatus,
  selectMessageTranslatable,
  viewerCoreSelectors.selectContactId,
  viewerCoreSelectors.selectUserLang,
  selectMessageContentLanguage,
  viewerCoreSelectors.selectDefaultLanguage,
  getRouteDataPage,
  (status, translatable, contactId, userLang, messageLang, companyLang, page) => {
    const currentLang = messageLang?.slice(0, 2) || companyLang?.slice(0, 2);
    const shouldSkip = !userLang || userLang === currentLang;

    if (!contactId || !translatable || page !== 'MESSAGE' || shouldSkip) {
      return TRANSLATION_STATUS.unneccesary;
    }
    return status;
  },
);

// navbar feature flag
export const navBarEnabled = createSelector(
  viewerCoreSelectors.selectFeatureFlags,
  (featureFlags) => featureFlags?.['viewer_navbar'],
);
// Modules
const translateNested = (
  currentValue: Record<string, any> | any[],
  currentPrefix: string,
  translations: Dictionary<{ id: string; value: string }>,
) => {
  const hasAvailableTranslationKeys = Object.keys(translations).some(
    (t) => t === currentPrefix || t.startsWith(`${currentPrefix}:`),
  );
  if (!hasAvailableTranslationKeys) {
    return currentValue;
  }
  const match = Object.keys(translations).find((t) => t === currentPrefix);
  if (match) {
    return translations[match].value;
  }

  if (Array.isArray(currentValue)) {
    return currentValue.map((item, index) => {
      return translateNested(item, `${currentPrefix}:${index}`, translations);
    });
  }
  return Object.keys(currentValue).reduce(
    (acc, key) => {
      return {
        ...acc,
        [key]: translateNested(acc[key], `${currentPrefix}:${key}`, translations),
      };
    },
    { ...currentValue },
  );
};
export const selectModuleTranslationEntities = createSelector(selectAppState, (state) =>
  fromApp.moduleTranslationsAdapter.getSelectors().selectEntities(state.moduleTranslations),
);
export const selectAllModuleEntities = createSelector(selectAppState, (state) =>
  fromApp.moduleAdapter.getSelectors().selectEntities(state.modules),
);
export const selectModuleEntities = createSelector(
  selectAllModuleEntities,
  selectModuleTranslationEntities,
  selectCurrentAutoTranslationStatus,
  (modules, translations, autoTranslationStatus) => {
    if (autoTranslationStatus !== TRANSLATION_STATUS.translated) {
      return modules;
    }
    return Object.keys(modules).reduce((all, moduleRef) => {
      const moduleWithTranslations = translateNested(modules[moduleRef], moduleRef, translations);

      return {
        ...all,
        [moduleRef]: moduleWithTranslations,
      };
    }, modules);
  },
);
export const getModuleByModuleRef = ({ moduleRef }: { moduleRef: string }) =>
  createSelector(selectModuleEntities, (moduleEntities) => (moduleRef ? moduleEntities[moduleRef] : null));
export const selectCurrentMessagePageModules = createSelector(
  selectCurrentMessagePage,
  selectModuleEntities,
  (page, moduleEntities) => {
    return (page?.modules || []).map((moduleRef) => moduleEntities[moduleRef]);
  },
);
export const selectCurrentMessageModules = createSelector(
  selectCurrentMessagePages,
  selectModuleEntities,
  (pages, moduleEntities) => {
    return pages.reduce((acc, page) => {
      return [...acc, ...(page?.modules || []).map((moduleRef) => moduleEntities[moduleRef])];
    }, []);
  },
);

// Academy
export const selectMessageAcademy = createSelector(selectCurrentMessage, (state) => state?.academy);
export const selectCurrentAcademyId = createSelector(selectMessageAcademy, (state) => state?.id);
export const selectCurrentAcademy = createSelector(
  selectMessageAcademy,
  selectModuleTranslationEntities,
  selectCurrentAutoTranslationStatus,
  (currentAcademy, translations, autoTranslationStatus) => {
    if (autoTranslationStatus !== TRANSLATION_STATUS.translated || !currentAcademy) {
      return currentAcademy;
    }

    const academyWithTranslations = translateNested(currentAcademy, `academy:${currentAcademy.id}`, translations);
    return academyWithTranslations;
  },
);

// Other

export const selectCurrentMessagePageContact = createSelector(
  selectCurrentMessagePage,
  (page: Page) => page && page.contact,
);
export const getMessageByKeycode = (messageId: string) =>
  createSelector(selectMessageEntities, (entities) => {
    const message = entities?.[messageId] as MessageNew;
    return message || null;
  });
export const selectIsSpecialMessage = createSelector(selectMessageType, (type) => {
  return ['smsverification'].includes(type);
});
export const selectCurrentMessagePageVisibleModules = createSelector(
  selectCurrentMessagePage,
  selectIsTest,
  selectModuleEntities,
  (page, isTest, moduleEntities) => {
    if (!page) {
      return [];
    }

    return page.modules
      .map((moduleRef) => moduleEntities[moduleRef])
      .filter((module) => {
        // Image placeholders are only visible in composer and preview
        if (['image'].includes(module.type)) {
          return module.url || isTest;
        }
        if (['media'].includes(module.type)) {
          return module.media?.length || isTest;
        }

        return true;
      });
  },
);

export const selectCurrentMessagePageVisibleModulesLoaded = createSelector(
  selectCurrentMessagePageVisibleModules,
  (modules) =>
    modules.every((module: MsgModule) => {
      if (module.type === 'messagelist') {
        return module.initialLoaded;
      }
      return true;
    }),
);

export const selectMessageQuestionnaireLatestPageIndex = createSelector(
  selectCurrentMessagePages,
  selectModuleEntities,
  selectMessageQuestionnaire,
  (pages, moduleEntities, questionnaire) => {
    const page = pages.find((p) => {
      return (
        p.last ||
        p.modules
          .map((moduleRef) => moduleEntities[moduleRef])
          .filter((m) => (m.type === 'buttongroup' && m.mode === 'quiz') || m.type === 'confirm')
          .some((m) => {
            const data = questionnaire[getModuleRef(m)];
            if (m.type === 'buttongroup') {
              return !data || !data.completed;
            } else if (m.type === 'confirm') {
              return !data || !data.value;
            }
            return false;
          })
      );
    });
    return page.index;
  },
);

export const selectUpdateContactInfoModalOpen = createSelector(
  selectAppState,
  (state) => state.showUpdateContactInfoModal,
);

// Themes
export const selectThemeEntities = createSelector(selectAppState, (state) =>
  fromApp.themeAdapter.getSelectors().selectEntities(state.themes),
);
export const selectCurrentTheme = createSelector(
  selectThemeEntities,
  selectMessageEntities,
  viewerCoreSelectors.selectCurrentKeycode,
  viewerCoreSelectors.selectPreviousKeycode,
  (themeEntities, messageEntities, currentMessageId, previousMessageId) => {
    const themeId =
      messageEntities?.[currentMessageId]?.themeId || messageEntities?.[previousMessageId]?.themeId || DEFAULT_THEME_ID;
    return themeEntities[themeId];
  },
);

// Sidebars
export const selectSidebarEntities = createSelector(selectAppState, (state) =>
  fromApp.sidebarAdapter.getSelectors().selectEntities(state.sidebars),
);
export const selectCurrentSidebar = createSelector(
  selectSidebarEntities,
  selectMessageEntities,
  viewerCoreSelectors.selectCurrentKeycode,
  viewerCoreSelectors.selectPreviousKeycode,
  (sidebarEntities, messageEntities, currentMessageId, previousMessageId) => {
    if (messageEntities?.[currentMessageId]?.sidebarId === null) {
      return null;
    }
    const sidebarId = messageEntities?.[currentMessageId]?.sidebarId || messageEntities?.[previousMessageId]?.sidebarId;
    const sidebar = sidebarEntities?.[sidebarId] || null;
    return messageEntities?.[currentMessageId]?.sidebar || null; // sidebar;
  },
);
export const selectMenuSideBarIsOpen = createSelector(selectAppState, (state) => state.menuSideBarIsOpen);
export const selectSocialSidebarIsOpen = createSelector(selectAppState, (state) => state.socialSidebarIsOpen);

// Compositions
export const selectShowHeader = createSelector(
  selectCurrentMessage,
  selectCurrentTheme,
  selectTrackingAttributes,
  (message, theme, { isHomepage }) => {
    if (isHomepage || message?.id === theme.nav_click_target) {
      return true;
    }

    if (message?.type === 'authentication') {
      return false;
    }

    return !theme?.nav_header_hide;
  },
);

export const selectSidebarUnreadCounts = createSelector(
  selectCurrentSidebar,
  viewerCoreSelectors.selectUnreadCounts,
  (sidebar, unreads) => {
    if (!sidebar || !unreads) {
      return null;
    } else {
      return sidebar.menu_data.map((section) => {
        return section.items.map((item) => {
          const action = (item.actions && Array.isArray(item.actions) ? item.actions : []).find(
            (a) => a.type === 'go_to_message',
          );
          return action && item.show_unread && unreads?.[action.param] ? unreads[action.param] : 0;
        });
      });
    }
  },
);

export const selectHasSidebarUnreads = createSelector(
  selectCurrentSidebar,
  viewerCoreSelectors.selectUnreadCounts,
  (sidebar, unreads) => {
    if (!sidebar) {
      return false;
    } else {
      return sidebar.menu_data.some((section) => {
        return section.items.some((item) => {
          const action = (item.actions && Array.isArray(item.actions) ? item.actions : []).find(
            (a) => a.type === 'go_to_message',
          );
          return Boolean(action && unreads[action.param]);
        });
      });
    }
  },
);

export const selectHasSidebar = createSelector(
  selectIsSpecialMessage,
  getRouteDataPage,
  selectCurrentSidebar,
  (special, page, sidebar) => !special && page !== 'CHAT' && !!sidebar,
);

export const selectForceExpandSidebar = createSelector(
  selectHasSidebar,
  viewerCoreSelectors.selectMenuExpandedDesktop,
  (hasSidebar, menuExpandedDesktop) => {
    return hasSidebar && menuExpandedDesktop;
  },
);

export const selectProfileIsShown = createSelector(
  viewerCoreSelectors.selectContact,
  selectIsSpecialMessage,
  selectMessageAuthentication,
  (contact, special, authentication) => {
    return !special && contact && contact.id && authentication?.version !== 'v2';
  },
);

export const selectIsBackButtonShown = createSelector(
  getRouteDataPage,
  selectCurrentTheme,
  selectCurrentMessageId,
  selectHasNavigatedToMessage,
  (page, theme, messageId, hasNavigatedToMessage) => {
    if ((page === 'MESSAGE' && messageId === theme.nav_click_target) || !hasNavigatedToMessage) {
      return false;
    }

    return true;
  },
);

export const selectInsightsIsShown = createSelector(
  selectInsights,
  selectIsSpecialMessage,
  viewerCoreSelectors.selectContact,
  getRouteDataPage,
  selectRouteNestedParam('profileId'),
  navBarEnabled,
  (insights, special, contact, page, profileId, navBarEnabled) => {
    if (!insights.active || special) {
      return false;
    }

    const notMyProfile =
      ['ENGAGEMENT', 'KNOWLEDGE', 'POINTS', 'PULSE', 'RATING'].includes(page) && parseInt(profileId, 10) !== contact.id;
    const myprofile = ['knowledge', 'engagement', 'points', 'progress', 'rating'].includes(page);
    if (
      notMyProfile ||
      [
        'PROFILE',
        'CREATE_CONTACT',
        'SOCIAL',
        'SOCIAL_WALL',
        'DIRECTORY',
        'NOTIFICATIONS',
        'NOTIFICATION_SETTINGS',
        'NOTIFICATION_SETTINGS_PUBLISHED_CONTENT',
        'NOTIFICATION_SETTINGS_MENTIONS',
        'NOTIFICATION_SETTINGS_COMMENTS',
        'NOTIFICATION_SETTINGS_GROUPS',
        'NOTIFICATION_SETTINGS_CHAT',
        'NOTIFICATION_SETTINGS_TASKS',
        'TASK',
        'pulse',
        'SAVED_CONTENT',
      ].includes(page)
    ) {
      return false;
    }

    if (myprofile && navBarEnabled) {
      return false;
    }

    return true;
  },
);

export const selectCommentsVisible = createSelector(
  selectCurrentMessage,
  selectCurrentMessagePages,
  viewerCoreSelectors.selectCurrentPageId,
  selectMessageShareEnabled,
  (currentMessage, pages, currentPage, shareEnabled): boolean => {
    const commentsEnabled: boolean = currentMessage?.comments_enabled;
    const reactionsEnabled: boolean = currentMessage?.likes_enabled;

    if (reactionsEnabled || commentsEnabled || shareEnabled) {
      let selectedCommentsPages: number[] = currentMessage.comments_page;
      // make sure there is a matching number of pages
      if (
        !Array.isArray(selectedCommentsPages) ||
        selectedCommentsPages.length !== 1 || // although an array, ATM we only accept 1 page
        selectedCommentsPages.some(isNaN) ||
        selectedCommentsPages.some((pageNumber) => pageNumber <= 0) ||
        selectedCommentsPages.some((pageNumber) => pageNumber > pages.length)
      ) {
        selectedCommentsPages = null;
      }

      // default is null - meaning it is visible on all pages but if value exists, it is visible only on that page
      const pageShouldShowSocial =
        selectedCommentsPages === null || selectedCommentsPages.includes(currentPage === 0 ? 1 : currentPage);
      return pageShouldShowSocial;
    }

    return false;
  },
);

export const selectIfCurrentPageHasPagination = createSelector(
  selectMessagePagination,
  selectRouteNestedParam('pageId'),
  getRouteDataPage,
  (pagination, pageId, page) => {
    const index = <number>(isNaN(Number(pageId)) ? 0 : Number(pageId));
    return pagination && pagination.includes(index) && page === 'MESSAGE';
  },
);

export const selectIfUnsubscribeUrlIsShown = createSelector(
  selectIsSpecialMessage,
  selectMessageAllowUnsubscribe,
  getRouteDataPage,
  (special, allowUnsubscribe, page) => {
    if (page !== 'MESSAGE' || special) {
      return false;
    }
    return Boolean(allowUnsubscribe);
  },
);

export const selectAnalyticsTrackingData = createSelector(
  viewerCoreSelectors.selectTrackingAttributes,
  selectMessageType,
  viewerCoreSelectors.selectSocialEnabled,
  selectCurrentMessageId,
  (trackingAttributes, type, socialEnabled, currentMessageId) => {
    const gaEnabled = Boolean(trackingAttributes?.gaEnabled);
    const clientId = trackingAttributes?.clientId || 0;
    const clientName = trackingAttributes?.clientName || '';
    const socialPrivateGroups = +Boolean(trackingAttributes?.socialPrivateGroups);
    const isHomepage = +Boolean(trackingAttributes?.isHomepage);
    const messageId = currentMessageId || 0;
    const messageType = type || '';
    const socialWallActive = +Boolean(socialEnabled);

    return <GATrackingData>{
      gaEnabled,
      trackingData: {
        clientId,
        clientName,
        socialWallActive,
        socialPrivateGroups,
        messageId,
        messageType,
        isHomepage,
      },
    };
  },
);

export const selectContactPreferredLanguage = createSelector(
  viewerCoreSelectors.selectContact,
  selectAutoTranslationLangs,
  (contact, langs) => {
    return langs?.find((language) => language.languageCode === contact?.preferred_languages[0]) || null;
  },
);
