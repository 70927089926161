import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  HostBinding,
  Inject,
  Input,
  ViewChild,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BrowserService, noop } from '@ao/utilities';
import { timer } from 'rxjs';

@Component({
  selector: 'ao-social-wall-search-input',
  templateUrl: './social-wall-search-input.component.html',
  styleUrls: ['./social-wall-search-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => SocialWallSearchInputComponent),
    },
  ],
})
export class SocialWallSearchInputComponent implements AfterViewInit {
  @HostBinding('class.ao-social-wall-search-input') className = true;
  @ViewChild('input', { static: false }) input: ElementRef;
  @Input() autofocus = false;
  @Input() blockSpecialCharacters = true;

  @Input() placeholder: string;

  get hasValue() {
    return Boolean(this._value);
  }

  _value: string = null;
  private _onChange = noop;
  private _onTouched = noop;

  constructor(
    private cdr: ChangeDetectorRef,
    private browserService: BrowserService,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  ngAfterViewInit() {
    if (this.autofocus) {
      this.handleFocus();
    } else {
      this.removeFocusOnIOS();
    }
  }

  // Filter special characters on input
  onInput(event: Event) {
    if (this.blockSpecialCharacters) {
      const inputElement = event.target as HTMLInputElement;
      const disallowedPattern = /[*\\|[\]?~"<>+^&!(){}:/-]/g;
      const sanitizedValue = inputElement.value.replace(disallowedPattern, '');

      if (inputElement.value !== sanitizedValue) {
        inputElement.value = sanitizedValue;
        this._value = sanitizedValue;
        this.onChange(sanitizedValue);
      }
    } else {
      const inputElement = event.target as HTMLInputElement;
      this._value = inputElement.value;
      this.onChange(inputElement.value);
    }
  }

  private scrollInputIntoView() {
    this.input.nativeElement.scrollIntoView({ behavior: 'instant', block: 'center' });
  }

  private handleFocus() {
    this.focus();
    if (this.browserService.isIOS) {
      // used for avoiding native keyboard pushing the searchbar out of the view
      timer(50).subscribe(() => {
        this.scrollInputIntoView();
        this.focus();
      });
    }
  }
  private removeFocusOnIOS() {
    if (this.browserService.isIOS) {
      // Small delay to ensure the modal is fully opened
      timer(50).subscribe(() => {
        if (this.input?.nativeElement) {
          this.input.nativeElement.blur();
        }
      });
    }
  }

  onClearClick() {
    this._value = '';
    this.onChange('');
    this.focus();
  }

  onChange(value: string) {
    this._onChange(value);
  }

  focus() {
    (<HTMLElement>this.input.nativeElement).focus();
  }

  isFocused() {
    return this.document.activeElement === this.input.nativeElement;
  }

  writeValue(value: any) {
    this._value = value;
    this.cdr.markForCheck();
  }

  registerOnChange(fn: any) {
    this._onChange = fn;
  }

  registerOnTouched(fn: any) {
    this._onTouched = fn;
  }
}
