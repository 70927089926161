import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AngularFireMessaging, SERVICE_WORKER } from '@angular/fire/compat/messaging';
import { environment } from '@ao/environments';
import { BrowserService, onceWithLatest } from '@ao/utilities';
import { ViewerCoreFacade } from '@ao/viewer-core';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, filter, firstValueFrom, from, Observable, of } from 'rxjs';
import { map, take, tap, withLatestFrom } from 'rxjs/operators';

export enum PushSettingsSource {
  onboarding = 'onboarding',
  notificationSettings = 'notificationSettings',
  preOptIn = 'preOptIn',
}

/* 
simplified type for the MessagePayload from interface firebase.messaging.MessagePayload
*/
interface FirebaseMessagePayload {
  data?: {
    link?: string;
  };
  notification?: {
    body?: string;
    title?: string;
  };
}

@Injectable({ providedIn: 'root' })
export class PushNotificationsService {
  private fireMesssageSW = inject(SERVICE_WORKER);
  private angularFireMessaging = inject(AngularFireMessaging);
  private viewerCoreFacade = inject(ViewerCoreFacade);
  private http = inject(HttpClient);
  private cookieService = inject(CookieService);
  private browserService = inject(BrowserService);
  private destroyRef = inject(DestroyRef);

  public readonly currentPushPermissionState$ = new BehaviorSubject<PermissionState>(null);
  public pushMessageWhileInFront$: Observable<FirebaseMessagePayload> = new BehaviorSubject<FirebaseMessagePayload>(
    null,
  );

  private _currentToken?: string;
  private _currentPermissionState?: PermissionState;
  /**
   *
   * @description sets up the push notification permission handlers after checking if the service worker is registered.
   */
  async setupPushPermissionHandlers() {
    try {
      // service worker must be registered before we can use the firemessage tokenRequest - on first install or updates, this can take some seconds while
      await this.fireMesssageSW;
    } catch (e) {
      console.warn('error registering service worker', e);
      return;
    }

    // get initial permission state and token
    this._currentPermissionState = (await firstValueFrom(this.getCurrentNotificationPermission()))?.state;
    this.currentPushPermissionState$.next(this._currentPermissionState);

    // setup permission change detection, and update token
    onceWithLatest(
      this.viewerCoreFacade.contact$,
      this.viewerCoreFacade.contactAuthCode$,
      this.angularFireMessaging.requestToken,
      (contact, contactAuthCode, token) => {
        this._currentToken = token;
        this.setupPermissionChangesListener();
        this.setupFiremessageTokenChangesListener(contact.id, contact.client_id, contactAuthCode);

        if (this._currentPermissionState === 'granted') {
          this.updateToken(contact.id, contact.client_id, contactAuthCode, this._currentToken);
        }
      },
    );

    // only ask to enable push notifications on device if the user has not been asked before (state === 'prompt')
    if (this._currentPermissionState === 'prompt') {
      this.requestFiremessagePermission(PushSettingsSource.notificationSettings);
    }
  }

  // Using firemessage tokenRequest to prompt the user for device push notifications
  async requestFiremessagePermission(source: string) {
    onceWithLatest(
      this.viewerCoreFacade.contact$,
      this.viewerCoreFacade.contactAuthCode$,
      (contact, contactAuthCode) => {
        this.registerActionWithApi(contact.id, contact.client_id, 'pre', 'accepted', contactAuthCode);
        this.registerActionWithApi(contact.id, contact.client_id, 'system', 'shown', contactAuthCode, source);

        // this is the trigger for the native prompting
        this.angularFireMessaging.requestPermission.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((_token) => {
          // value returned is not used her due to this.setupFiremessageTokenChangesListener
        });
      },
    );
  }

  // This is the only listener for token changes
  setupFiremessageTokenChangesListener(contactId: number, clientId: number, contactAuthCode: string) {
    // expose the messages observable
    this.pushMessageWhileInFront$ = this.angularFireMessaging.messages;

    // We keep it subscribed to detect possible token changes while the tab is open
    // These are managed by firebase and possibly ocurr every 1h
    this.angularFireMessaging.tokenChanges
      .pipe(
        withLatestFrom(this.currentPushPermissionState$),
        tap(([token, devicePushPermissionState]) => {
          if (token && token !== this._currentToken) {
            this._currentToken = token;
            this.updateToken(contactId, clientId, contactAuthCode, token);
          } else {
            // do nothing if token is the same since pagelaod or last update
          }
          // for now we always register the devicePushPermission on token change
          this.registerChanges(
            contactId,
            clientId,
            contactAuthCode,
            devicePushPermissionState,
            PushSettingsSource.notificationSettings,
          );
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  setupPermissionChangesListener() {
    // We use simple navigator current permission state which is faster for ui updates than the fireMessageTokenChangesListener
    if (navigator && 'permissions' in navigator && (<any>navigator).permissions.query) {
      (<any>navigator).permissions.query({ name: 'notifications' }).then((notificationPerm) => {
        notificationPerm.onchange = () => {
          this.currentPushPermissionState$.next(notificationPerm.state);
        };
      });
    }
  }

  registerChanges(
    contactId: number,
    clientId: number,
    contactAuthCode: string,
    state: PermissionState,
    source: string,
  ) {
    if (state === 'denied') {
      this.registerActionWithApi(contactId, clientId, 'system', 'denied', contactAuthCode, source);
      this.viewerCoreFacade.updateSystemAllowPushNotification(false);
    } else if (state === 'granted') {
      this.registerActionWithApi(contactId, clientId, 'system', 'accepted', contactAuthCode, source);
      this.viewerCoreFacade.updateSystemAllowPushNotification(true);
    } else if (state === 'prompt') {
      this.registerActionWithApi(contactId, clientId, 'system', 'prompt', contactAuthCode, source);
      this.viewerCoreFacade.updateSystemAllowPushNotification(null);
    }
  }

  getCurrentNotificationPermission(): Observable<PermissionStatus> {
    // For native webviews we assume that permissions are enabled until we get the bridge implementation
    if (this.browserService.isNativeAppCookieSet()) {
      return of({ state: 'granted' } as PermissionStatus);
    }
    // This is also supported in native webview - but never prompted so moved below the check for native cookie
    if (navigator && 'permissions' in navigator && navigator.permissions.query) {
      // We get current permission state
      return from(navigator.permissions.query({ name: 'notifications' }));
    }
    // Will display fallback message - that the device does not support push settings
    return of(null);
  }

  // type: 'pre' or 'system'
  registerActionWithApi(
    contactId: number,
    clientId: number,
    type: string,
    action: string,
    contactAuthCode: string,
    source?: string,
  ) {
    const contactDeviceCookie = this.cookieService.get('contact_device');
    if (!contactDeviceCookie) {
      return;
    }
    const data = {
      browserId: contactDeviceCookie || null,
      clientId: clientId,
      ...(source ? { source } : {}),
    };
    const url = `${environment.apiBaseUrl}/api/v1/device/${contactId}/register-action/${type}/${action}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'auth-code': contactAuthCode,
      }),
    };
    this.http.put(url, { data }, httpOptions).subscribe();
  }

  private updateToken(contactId: number, clientId: number, contactAuthCode: string, registrationToken: string) {
    const contactDeviceCookie = this.cookieService.get('contact_device');
    const registrationInfo = {
      push_key: registrationToken,
      client_id: clientId,
    };
    const url = `${environment.apiBaseUrl}/api/v1/device/${contactId}`;
    const body = contactDeviceCookie ? { update: [registrationInfo] } : { add: [registrationInfo] };
    const httpOptions = {
      headers: new HttpHeaders({
        'auth-code': contactAuthCode,
      }),
    };
    this.http.put(url, body, httpOptions).subscribe();
  }

  // #region cometchat push notification settings
  // could be move to cometchat service
  private cometchatPushNotificationSettingsSubject = new BehaviorSubject(null);
  cometchatPushNotificationSettings$ = this.cometchatPushNotificationSettingsSubject.asObservable().pipe(
    filter((res) => !!res),
    map((res) => res['user-settings'].dnd),
  );
  private cometchatPushNotificationLoadedSubject$ = new BehaviorSubject(true);
  cometchatPushNotificationLoaded$ = this.cometchatPushNotificationLoadedSubject$.asObservable();

  async toggleCometchatNotifications() {
    this.cometchatPushNotificationLoadedSubject$.next(false);
    this.cometchatPushNotificationSettings$.pipe(take(1)).subscribe(async (currentDndStatus) => {
      const { CometChat } = await import('@cometchat/chat-sdk-javascript');

      const userSettings = {
        'user-settings': {
          dnd: !currentDndStatus,
          chat: {
            allow_only_mentions: false,
            mute_group_actions: false,
            mute_all_guids: false,
            mute_all_uids: false,
          },
          call: {
            mute_all_guids: false,
            mute_all_uids: false,
          },
        },
      };

      CometChat.callExtension('push-notification', 'POST', 'v1/user-settings', userSettings).then((_) => {
        this.getCometchatPushNotificationStatus();
      });
    });
  }

  async getCometchatPushNotificationStatus() {
    const { CometChat } = await import('@cometchat/chat-sdk-javascript');

    CometChat.callExtension('push-notification', 'GET', 'v1/user-settings', null)
      .then((settings) => {
        this.cometchatPushNotificationSettingsSubject.next(settings);
        this.cometchatPushNotificationLoadedSubject$.next(true);
      })
      .finally(() => {
        this.cometchatPushNotificationLoadedSubject$.next(true);
      });
  }
}
