import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ReactionPickerService {
  private reactionPickerOpenById$ = new Subject<string | false>();
  currentReactionPicker$ = this.reactionPickerOpenById$.asObservable();

  show(reactionPickerId: string) {
    this.reactionPickerOpenById$.next(reactionPickerId);
  }

  hide() {
    this.reactionPickerOpenById$.next(false);
  }
}
